$color-primary: #df9a48;
$color-dark: #1f1f1f;

$color-text-dark: #000;
$color-text-light: #fff;

$overlay: linear-gradient(#1f1f1f75, #1f1f1f75);
$overlay-dark: linear-gradient(#1f1f1fa3, #1f1f1fa3);

$font-primary: "Raleway", sans-serif;
$font-secondary: "Georgia", serif;
