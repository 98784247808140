.h-floating-socials {
  position: fixed;
  right: 3.5rem;
  bottom: 4.4rem;
  z-index: 100;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4.5rem;

  & > .h-socials-link--instagram {
    background-image: url("../assets/images/icons/instagram-white.png");
  }

  & > .h-socials-link--spotify {
    background-image: url("../assets/images/icons/spotify-white.png");
  }

  & > .h-socials-link--facebook {
    background-image: url("../assets/images/icons/facebook-white.png");
  }
}

@include more-than(lg) {
  .h-floating-socials {
    right: 14rem;
    bottom: 14rem;
    display: inline-flex;

    & > .h-socials-link--instagram {
      background-image: url("../assets/images/icons/instagram-white.png");

      &:hover {
        background-image: url("../assets/images/icons/instagram.png");
        transform: scale(1.12);
      }
    }

    & > .h-socials-link--spotify {
      background-image: url("../assets/images/icons/spotify-white.png");

      &:hover {
        background-image: url("../assets/images/icons/spotify.png");
        transform: scale(1.12);
      }
    }

    & > .h-socials-link--facebook {
      background-image: url("../assets/images/icons/facebook-white.png");

      &:hover {
        background-image: url("../assets/images/icons/facebook.png");
        transform: scale(1.12);
      }
    }
  }
}
