.h-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2.3rem 2.7rem 0 2.7rem;
}

.h-navbar__logo {
  position: relative;
  z-index: 25;
  width: 5.4rem;
  height: 7.6rem;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.h-navbar__mobile-nav {
  position: relative;
  z-index: 20;
  display: block;
  user-select: none;
  width: 4rem;
}

.h-navbar__mobile-nav a {
  text-decoration: none;
  color: $color-text-light;
  transition: color 0.3s ease;
}

.h-navbar__mobile-nav input {
  position: absolute;
  top: -7px;
  left: -5px;
  z-index: 2;
  display: block;
  opacity: 0;
  width: 4rem;
  height: 3.2rem;
  cursor: pointer;
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
.h-navbar__mobile-nav span {
  position: relative;
  z-index: 20;
  display: block;
  width: 3rem;
  height: 3px;
  margin-bottom: 10px;
  border-radius: 3px;
  background: $color-text-light;
  transform-origin: 4px 0;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
}

.h-navbar__mobile-nav span:first-child {
  transform-origin: 0% 0%;
}

.h-navbar__mobile-nav span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
.h-navbar__mobile-nav input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, 7px);
}

/*
 * But let's hide the middle one.
 */
.h-navbar__mobile-nav input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
.h-navbar__mobile-nav input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -7px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
.h-navbar__mobile-menu {
  position: fixed;
  top: 0;
  left: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 5rem;
  list-style-type: none;
  background: $color-dark;

  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transition: left 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  -webkit-font-smoothing: antialiased;
}

.h-navbar__mobile-menu li {
  font-size: 2.2rem;
  text-align: center;
  text-transform: uppercase;
}

.h-navbar__mobile-menu > a:not(:last-child) li {
  margin-bottom: 4.5rem;
}

/*
 * And let's slide it in from the left
 */
.h-navbar__mobile-nav input:checked ~ ul {
  left: 0;
}

.h-navbar__mobile-menu-logo {
  width: 20rem;
  height: 6.3rem;
  margin-top: -15rem;
  margin-bottom: 8.7rem;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include more-than(lg) {
  .h-navbar {
    padding: 7rem 13rem;
  }

  .h-navbar__logo {
    width: 7.7rem;
    height: 11rem;
  }

  .h-navbar__mobile-nav {
    width: 40rem;
    height: auto;
    padding-top: 4rem;
  }

  .h-navbar__mobile-nav input,
  .h-navbar__mobile-nav span {
    display: none;
  }

  .h-navbar__mobile-menu {
    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4.5rem;
    width: auto;
    height: auto;
    padding: 0;
    list-style-type: none;
    background: transparent;
    transition: none;
  }

  .h-navbar__mobile-menu li {
    font-size: 2rem;
    transition: color 0.2s ease-in;

    &:hover {
      color: $color-primary;
    }
  }

  .h-navbar__mobile-menu > .h-navbar__mobile-menu-logo {
    display: none;
  }

  .h-navbar__mobile-menu > a:not(:last-child) li {
    margin-bottom: 0;
  }
}
