.h-socials-link {
  display: block;
  width: 3rem;
  height: 3rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in;

  &--instagram {
    border-radius: 8px;
    background-image: url("../assets/images/icons/instagram.png");

    &:hover {
      background-image: url("../assets/images/icons/instagram-white.png");
    }
  }

  &--instagram-yellow {
    background-image: url("../assets/images/icons/instagram.png") !important;
  }

  &--spotify {
    border-radius: 50%;
    background-image: url("../assets/images/icons/spotify.png");

    &:hover {
      background-image: url("../assets/images/icons/spotify-white.png");
    }
  }

  &--spotify-yellow {
    background-image: url("../assets/images/icons/spotify.png") !important;
  }

  &--facebook {
    border-radius: 50%;
    background-image: url("../assets/images/icons/facebook.png");

    &:hover {
      background-image: url("../assets/images/icons/facebook-white.png");
    }
  }

  &--facebook-yellow {
    background-image: url("../assets/images/icons/facebook.png") !important;
  }
}

@include more-than(lg) {
  .h-socials-link {
    width: 4.4rem;
    height: 4.4rem;

    &--instagram {
      border-radius: 1.2rem;
    }
  }
}
