.text-center {
  text-align: center !important;
}

.position-relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.display-none {
  display: none !important;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
