.h-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-text-light;

  &__item:not(:last-child) {
    margin-bottom: 6rem;
  }

  &__desc {
    max-width: 25rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.4;
    font-family: $font-primary;
  }
}

@include more-than(lg) {
  .h-services {
    flex-direction: row;
    align-items: flex-start;
    gap: 8rem;
    padding: 10rem 0;

    &__item:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

@include more-than(xxl) {
  .h-services__desc {
    max-width: 33rem;
    font-size: 2rem;
    line-height: 1.25;
  }
}
