.h-section {
  position: relative;
  padding: 5.5rem 3.5rem;
  overflow: hidden;

  &--slideshow {
    padding: 18rem 3.5rem;
    background: $overlay-dark center / cover no-repeat, url("../assets/images/7.jpg") 100% 100% / cover no-repeat;
  }

  &__content {
    position: inherit;
    z-index: 5;
    max-width: 97rem;

    &--slim {
      max-width: 83rem;
    }
  }
}

.h-decor-1 {
  position: absolute;
  bottom: -28%;
  left: -52%;
  z-index: 1;
  width: 200%;
  height: 55rem;
  background: url("../assets/images/icons/logo-background.png") center center/contain no-repeat;
}

.h-decor-2 {
  position: absolute;
  top: 49%;
  left: 48%;
  z-index: 1;
  width: 120%;
  height: 80rem;
  background: url("../assets/images/icons/logo-background.png") center center/contain no-repeat;
  transform: translate(-46%, -36%);
}

@include more-than(lg) {
  .h-section {
    padding: 9.5rem 3.5rem;

    &--slideshow {
      min-height: 90vh;
      padding: 0;
      // background-image: url("../assets/images/2.jpg");

      .h-section__content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: unset;
        margin-top: auto;
        background: #070707ab 0% 0% no-repeat padding-box;
      }
    }
  }

  .h-decor-1 {
    bottom: -90%;
    left: -37%;
    height: 150rem;
  }

  .h-decor-2 {
    top: -16%;
    left: 36%;
    height: 160%;
    background-size: contain;
  }
}

@include more-than(xl) {
  .h-section {
    &__content {
      margin: 0 auto;
    }
  }
}
