.h-creator {
  position: absolute;
  right: 50%;
  bottom: 1rem;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: center;
  color: $color-text-light;
  transform: translateX(50%);

  & > a {
    color: currentColor;
  }

  @include more-than(lg) {
    right: 3rem;
    bottom: 2rem;
    transform: none;
  }
}
