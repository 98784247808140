.h-contact {
  &__card {
    max-width: 24rem;

    &:not(:last-child) {
      margin-bottom: 3.4rem;
    }
  }

  &__card--inverted {
    margin-left: auto;
    text-align: right;

    & > .h-contact__image {
      margin-left: auto;
    }
  }

  &__image {
    width: 22rem;
    height: 22rem;
    margin-bottom: 2rem;
    overflow: hidden;
    border-radius: 50%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    margin-bottom: 2.3rem;
    font-weight: 600;
    font-size: 4rem;
    line-height: 1;
    font-family: $font-secondary;
  }

  &__links {
    padding: 0;
    margin: 0;
    list-style: none;

    & > li:not(:first-child) {
      margin-top: -0.5rem;
    }
  }

  &__link,
  &__link:link,
  &__link:visited {
    font-weight: 400;
    font-size: 2rem;
    text-decoration: none;
    color: $color-text-dark;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include more-than(xl) {
  .h-contact {
    margin-top: 14rem;

    &__card {
      display: flex;
      max-width: unset;
      margin-left: -15rem;

      & > .h-contact__info {
        margin-left: 3.5rem;
      }
    }

    &__card--inverted {
      flex-direction: row-reverse;
      margin-top: -12rem;
      margin-right: -15rem;

      & > .h-contact__image {
        margin-left: 0;
      }

      & > .h-contact__info {
        margin-right: 3.5rem;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 30rem;
    }

    &__name {
      font-size: 5rem;
      line-height: 1.2;
    }

    &__image {
      width: 32rem;
      height: 32rem;
      margin-bottom: 2rem;
    }
  }
}
