.h-btn {
  display: inline-block;
  padding: 1.2rem 3.2rem;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 1.2;
  font-family: $font-secondary;
  text-align: center;
  text-decoration: none;
  color: $color-text-light;
  border: 3px solid $color-primary;
  border-radius: 5rem;
  background-color: $color-primary;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

@include more-than(xl) {
  .h-btn:hover {
    color: $color-primary;
    background-color: $color-text-light;
  }
}
