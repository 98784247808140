.splide {
  display: none;

  &__image {
    width: 100%;
    height: 90vh;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@include more-than(lg) {
  .splide {
    display: block;
  }
}
