.h-hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: $overlay center / cover no-repeat, url("../assets/images/1.jpg") 37% 100% / 480% no-repeat;
}

.h-hero__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32rem;
  height: 11.2rem;
  transform: translate(-50%, -50%);

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include more-than(md) {
  .h-hero {
    background-position: center top;
    background-size: cover;
  }
}

@include more-than(xl) {
  .h-hero__logo {
    width: 61.5rem;
    height: 19.2rem;
  }
}

@include more-than(xxl) {
  .h-hero {
    background-position: center top;
  }
}
