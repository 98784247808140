.h-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  padding: 4rem 4rem 5rem 4rem;
  background-color: $color-text-dark;
}

@include more-than(lg) {
  .h-footer {
    padding: 3.3rem;
  }
}
