.h-h {
  margin: 0  0 4.4rem 0;
  font-weight: 400;
  font-family: $font-secondary;

  &--primary {
    font-size: 2.5rem;
    line-height: 1.2;

    & > strong {
      font-weight: 900;
      color: $color-primary;
    }
  }

  &--secondary {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2.5rem;
    text-transform: uppercase;
  }
}

.h-p {
  max-width: 88rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.4;
  font-family: $font-primary;
  text-align: justify;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  &:last-of-type {
    margin-bottom: 4.7rem;
  }

  & > strong {
    font-weight: 400;
  }
}

@include more-than(lg) {
  .h-h {
    margin: 0  0 4rem 0;

    &--primary {
      font-size: 5rem;
    }
  }

  .h-p {
    max-width: 80rem;
    font-size: 2rem;
    line-height: 1.5;

    &:last-of-type {
      margin-bottom: 4rem;
    }
  }
}

@include more-than(xxl) {
  .h-h--secondary {
    margin-bottom: 1.4rem;
    font-size: 3.5rem;
    line-height: 1.15;
  }
}
