html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 3rem;
  font-family: $font-primary;
  color: $color-text-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include more-than(xl) {
    font-size: 2rem;
  }
}

main {
  margin-top: 8.65rem;

  @include more-than(xl) {
    margin-top: 10rem;
  }
}
