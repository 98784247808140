@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: Raleway;
  src: url("../assets/fonts/Raleway-Regular.woff2") format("woff2"),
    url("../assets/fonts/Raleway-Regular.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: Raleway;
  src: url("../assets/fonts/Raleway-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Raleway-ExtraBold.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: Georgia;
  src: url("../assets/fonts/georgia-regular.woff2") format("woff2"),
    url("../assets/fonts/georgia-regular.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: Georgia;
  src: url("../assets/fonts/georgia-bold.woff2") format("woff2"),
    url("../assets/fonts/georgia-bold.woff") format("woff");
  font-display: fallback;
}
